import { Iconify, Popover as MenuPopover } from '@autone/ui';
import chevronDown from '@iconify/icons-eva/chevron-down-fill';
import chevronUp from '@iconify/icons-eva/chevron-up-fill';
import { Box, Button, MenuItem, type SxProps, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { SELECT_ORDER_BY, SELECT_SORT } from '../redux/bestsellersSort';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '../redux/store';
import { Mixpanel } from '../utils/mixpanel';
import { ORDER, SORT_PRODUCTS } from '../utils/mixpanel/eventTypes';

function SortBy({ sx }: { sx?: SxProps }) {
  const { sortBy, orderBy } = useSelector((state) => state.bestsellersSort);

  const dispatch = useDispatch();

  const SORT_BY_OPTIONS = [
    { value: 'Sales.totalSalesValue', label: 'Sales value' },
    { value: 'Sales.totalSalesQuantity', label: 'Sales volume' },
  ];

  const ORDER_BY_OPTIONS = [
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
  ];

  function renderLabel(label: string) {
    if (label === 'Sales.totalSalesValue') {
      return 'Sales value';
    }
    if (label === 'Sales.totalSalesQuantity') {
      return 'Sales volume';
    }

    return null;
  }

  const [open, setOpen] = useState<Element | null>(null);
  const [openOrder, setOpenOrder] = useState<Element | null>(null);

  const handleOpen = (currentTarget: Element | null) => {
    setOpen(currentTarget);
  };

  const handleOpenOrder = (currentTarget: Element | null) => {
    setOpenOrder(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    setOpenOrder(null);
  };

  const handleSortBy = (value: string) => {
    handleClose();
    Mixpanel.track(SORT_PRODUCTS, { sort_by: value });
    dispatch(SELECT_SORT(value));
  };

  const handleOrderBy = (value: string) => {
    handleClose();
    Mixpanel.track(ORDER, { sort_by: value });
    dispatch(SELECT_ORDER_BY(value));
  };

  return (
    <Box sx={{ ...sx }}>
      <Button
        color="inherit"
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={<Iconify icon={open ? chevronUp : chevronDown} />}
        sx={{ textTransform: 'none' }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Sort by:&nbsp;
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            {renderLabel(sortBy)}
          </Typography>
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortBy}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>

      <Button
        color="inherit"
        disableRipple
        onClick={(event) => handleOpenOrder(event.currentTarget)}
        endIcon={<Iconify icon={open ? chevronUp : chevronDown} />}
        sx={{ textTransform: 'none' }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Order by:&nbsp;
          <Typography
            component="span"
            variant="body1"
            sx={{ color: 'text.secondary' }}
          >
            {orderBy}
          </Typography>
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={openOrder}
        open={Boolean(openOrder)}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {ORDER_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortBy}
            onClick={() => handleOrderBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </Box>
  );
}

SortBy.propTypes = {
  sx: PropTypes.object,
};

export default SortBy;
