import { SIDEBAR_FILTER_ORDER } from '@autone/utils';

import { type Filter, type FilterWithActive } from '../../types';
import { sortByList } from '../../utils/sorting';

export const getSortedFilters = <T>({
  activePage,
  filterOptions,
  filterSortKey,
}: {
  activePage: string;
  filterOptions: Record<string, FilterWithActive<T>[] | undefined>;
  filterSortKey: string;
}) => {
  const filterArray = filterOptions?.[activePage];

  const sortedFilters = sortByList<
    FilterWithActive<T>,
    keyof FilterWithActive<T>
  >(filterArray, SIDEBAR_FILTER_ORDER, filterSortKey as keyof Filter);

  return { [activePage]: sortedFilters };
};

export const getActiveFilters = <T>(filters?: FilterWithActive<T>[]) => {
  if (!filters || filters.length === 0) {
    return [];
  }

  const optionsFiltered =
    filters &&
    filters.map((item) => ({
      ...item,
      options: (item.options || [])
        .filter((option) => option.active)
        .map(({ active: _, ...rest }) => ({
          ...rest,
        })),
    }));

  return optionsFiltered.filter((obj) => obj.options.length !== 0);
};
