import { capitalizeFirstLetter } from '@autone/utils';
import PropTypes from 'prop-types';
import React from 'react';

import { noDimension } from '../../config/consts';

const DimensionDrilldown = ({ dataRow, cubeQuery, isLoading }) => {
  return (
    <span>
      {!isLoading &&
        dataRow &&
        cubeQuery &&
        (dataRow[cubeQuery[0]?.dimensionName[0]]
          ? capitalizeFirstLetter(dataRow[cubeQuery[0]?.dimensionName[0]])
          : noDimension)}
    </span>
  );
};

export default DimensionDrilldown;

DimensionDrilldown.propTypes = {
  isLoading: PropTypes.bool,
  dataRow: PropTypes.object,
  cubeQuery: PropTypes.array,
};
