import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAutoneCube, useBuildCubeQuery } from '../cube';
import { LOAD_FILTER_OPTIONS } from '../redux/filters';
import { type ActivePage } from '../types';
import {
  type CustomerFilterConfig,
  type FilterOutput,
  type FilterQueryOutput,
} from '../types/filters';
import {
  combineProductIdFilterResults,
  getFilterItems,
  getFilterOptionResults,
  getFilterQuery,
  // @ts-ignore
} from '../utils';
import { countryDict } from '../utils/getCountryDict';

const useFilters = (props: {
  dimensions: CustomerFilterConfig[];
  page: ActivePage;
}) => {
  const { dimensions, page } = props || {};
  const dispatch = useDispatch();
  const queryGroup = getFilterQuery(dimensions);

  const { cubeQuery } = useBuildCubeQuery({
    query: queryGroup,
    filters: {
      retail: [],
    },
    activePage: page,
    filterQuery: true,
  });

  const { resultSet } = useAutoneCube({
    query: cubeQuery,
    type: 'table',
  }) as { resultSet: FilterQueryOutput[] };

  const transformFilterData = useCallback(
    (results: FilterQueryOutput[]): FilterOutput[] => {
      const filterData =
        results &&
        results.map(({ results, dataKey }) => {
          // filter dimensions config, to get meta data
          const dimensionInfo =
            dimensions && dimensions.find((dim) => dim.dimension === dataKey);

          // get filter options, some transformation is happening in this function for product_id and Ticket.shippingCountry
          const options = getFilterOptionResults(results, dataKey, countryDict);

          // remove empty options from filters
          const filteredOptions = options?.filter(
            ({ id }: { id: string }) => id,
          );

          // get filter items, including options. This results in two filter results for product_id (for sales and inventory)
          return getFilterItems(dataKey, dimensionInfo, filteredOptions);
        });

      // combine product results into a single filter
      return combineProductIdFilterResults(filterData);
    },
    [dimensions],
  );

  useEffect(() => {
    if (resultSet) {
      const data = transformFilterData(resultSet);
      const dataFiltered =
        data &&
        data.filter(({ options }) => options.filter(({ id }) => id).length > 0);

      dispatch(LOAD_FILTER_OPTIONS({ data: dataFiltered, dimension: page }));
    }
  }, [resultSet, dispatch, page, transformFilterData]);

  return {};
};

export default useFilters;
