import { Box } from '@mui/material';

import { type FitlterOption } from '../dropdown-filter/DropdownFilter.types';

import FilterChip from './FilterChip';

const FilterTooltip = ({
  filters,
  handleRemoval,
  dimensionId,
}: {
  filters?: FitlterOption[];
  handleRemoval: (dimensionId?: string, id?: string | null) => void;
  dimensionId?: string;
}) => (
  <Box>
    {filters &&
      filters.map(({ title, id }, idx) => (
        <FilterChip
          id={id}
          title={title}
          dimensionId={dimensionId}
          handleRemoval={handleRemoval}
          key={idx}
        />
      ))}
  </Box>
);

export default FilterTooltip;
