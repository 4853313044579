import { Label } from '@autone/ui';
import { fNumber, fReadableCurrency } from '@autone/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { compare } from '../../config/retail/calcs';

const Compare = ({ dataRow, dataKey, metric, currentKey, format }) => {
  const { selectedCurrency } = useSelector((state) => state.priceOptions);

  const currentVal =
    dataRow && Number.isNaN(1 * dataRow[currentKey])
      ? 0
      : 1 * dataRow[currentKey] || 0;
  const compareVal =
    dataRow && Number.isNaN(1 * dataRow[dataKey])
      ? 0
      : 1 * dataRow[dataKey] || 0;

  if (dataRow && (currentVal == null || compareVal == null)) {
    return 'nm';
  }

  if (metric === 'value') {
    return (
      <Label
        variant="filled"
        color={dataRow && currentVal > compareVal ? 'success' : 'error'}
      >
        {dataRow && selectedCurrency && currentVal > compareVal
          ? `+${fReadableCurrency(
              compare(dataRow, dataKey, currentKey),
              selectedCurrency,
            )}`
          : `${fReadableCurrency(
              compare(dataRow, dataKey, currentKey),
              selectedCurrency,
            )}`}
      </Label>
    );
  }
  if (metric === 'value-non-thousand') {
    return (
      <Label
        variant="filled"
        color={dataRow && currentVal > compareVal ? 'success' : 'error'}
      >
        {dataRow && selectedCurrency && currentVal > compareVal
          ? `+${fReadableCurrency(
              compare(dataRow, dataKey, currentKey),
              selectedCurrency,
              1,
            )}`
          : `${fReadableCurrency(
              compare(dataRow, dataKey, currentKey),
              selectedCurrency,
              1,
            )}`}
      </Label>
    );
  }
  if (metric === 'volume') {
    return (
      <Label
        variant="filled"
        color={dataRow && currentVal > compareVal ? 'success' : 'error'}
      >
        {dataRow && currentVal > compareVal
          ? `+${fNumber(compare(dataRow, dataKey, currentKey), format)}`
          : `${fNumber(compare(dataRow, dataKey, currentKey), format)}`}
      </Label>
    );
  }
  return <>No calculation type</>;
};

export default Compare;

Compare.propTypes = {
  dataRow: PropTypes.object,
  dataKey: PropTypes.string,
  metric: PropTypes.string,
  currentKey: PropTypes.string,
  format: PropTypes.string,
};
