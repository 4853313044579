import { LEGACY_FILTER_APPS } from '@autone/utils';
import { List, Skeleton } from '@mui/material';

import FilterItems, { type OptionsType } from './FilterItems';

export default function FilterList({
  filterOptions,
  handleFilterClickCallback,
  handleSelectDeselectAllByDimension,
  numOptionsDefaultOpen = 0,
  appName,
  labels,
}: {
  filterOptions?: {
    dimension: string;
    dimension_id: string;
    title?: string;
    name?: string | null;
    options: OptionsType;
  }[];
  handleFilterClickCallback: (
    dimension: string,
    id: string,
    active: boolean,
    type: string,
    description: string,
  ) => void;
  handleSelectDeselectAllByDimension: ({
    dimension,
    active,
    dimensionOptions,
  }: {
    dimension: string;
    active: boolean;
    dimensionOptions: OptionsType;
  }) => void;
  numOptionsDefaultOpen: number;
  appName: string;
  labels: {
    searchPlaceholder?: string;
    selectAll: (optionCount: number) => string;
    clearAll: string;
    loadMore: string;
  };
}) {
  // if filters are undefined then show skeleton placeholders
  if (!filterOptions) {
    return Array.from({ length: 3 }, (_, k) => k + 1).map((item) => (
      <Skeleton key={item} sx={{ ml: 3, mr: 3 }} height={60} />
    ));
  }

  return (
    <List sx={{ mt: 1 }}>
      {filterOptions.map(
        ({ dimension, dimension_id: dimensionId, title, name, options }) =>
          options?.length > 0 && (
            <FilterItems
              key={
                LEGACY_FILTER_APPS.includes(appName) ? dimension : dimensionId
              }
              dimension={
                LEGACY_FILTER_APPS.includes(appName) ? dimension : dimensionId
              }
              title={LEGACY_FILTER_APPS.includes(appName) ? title : name}
              options={options}
              labels={labels}
              activeFilters={options.filter((item) => item.active).length}
              handleFilterClickCallback={handleFilterClickCallback}
              handleSelectDeselectAllByDimension={
                handleSelectDeselectAllByDimension
              }
              numOptionsDefaultOpen={numOptionsDefaultOpen}
            />
          ),
      )}
    </List>
  );
}
