import { CubeContext } from '@cubejs-client/react';
import { useCallback, useContext, useEffect, useState } from 'react';

import useDeepCompareMemorise from './hooks/deep-compare-memorise';
import { useIsMounted } from './hooks/is-mounted';

export function useAutoneCube({ query, type }, options = {}) {
  const isMounted = useIsMounted();
  const [isLoading, setLoading] = useState(false);
  const [resultSet, setResultSet] = useState(null);
  const context = useContext(CubeContext);

  const loadQueries = useCallback(
    (queryArray) => {
      const { resetResultSetOnChange } = options;

      const cubejsApi = options.cubejsApi || context?.cubejsApi;

      setResultSet({
        isLoading: true,
        ...(resetResultSetOnChange ? { resultSet: null } : {}),
        error: null,
      });

      const resultPromises = Promise.all(
        queryArray.map(
          async (
            { query, dataKey, measureName, dimensionName, timeDimension },
            i,
          ) => {
            const data = await cubejsApi.load(query, {
              mutexObj: { key: i },
              mutexKey: 'query',
            });
            const formattedData = data?.loadResponses[0]?.data;

            if (type === 'chart') {
              return {
                results: formattedData,
                dataKey,
                measureName,
                dimensionName,
                timeDimension,
              };
            }

            if (type === 'kpi') {
              return { results: formattedData, dataKey, measureName };
            }

            if (type === 'table') {
              return {
                results: formattedData,
                dataKey,
                measureName,
                dimensionName,
              };
            }
            return undefined;
          },
        ),
      );

      resultPromises
        .then((resultSet) => {
          if (isMounted()) {
            setResultSet({
              resultSet,
              error: null,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          if (isMounted()) {
            setResultSet({
              ...(resetResultSetOnChange ? { resultSet: null } : {}),
              error,
              isLoading: false,
            });
          }
        });
    },
    [context, isMounted, options, type],
  );

  // whenever query changes this is fired
  useEffect(() => {
    async function queryHandler() {
      if (query) {
        // Only set loading if query is defined
        setLoading(true);
        loadQueries(query);
      }
    }
    queryHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDeepCompareMemorise(query)]);

  return {
    isLoading,
    ...resultSet,
  };
}
