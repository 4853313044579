import {
  FilterChipOld,
  FilterTooltip,
  Label,
  LightTooltip,
  Panel,
} from '@autone/ui';
import { capitalizeFirstLetter } from '@autone/utils';
import { Chip } from '@mui/material';
import { filter as _filter } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  UPDATE_COMPARISON_RANGE,
  UPDATE_PRIMARY_RANGE,
} from '../redux/explorer';
import { SELECT_DESELECT_FILTER } from '../redux/filters';
import { SET_SELECTED_DATE } from '../redux/retailCalendar';
import { LOAD_SELECTED_PRODUCT } from '../redux/selected-product';
import { useAppSelector } from '../redux/store';

import RemoveAllFilters from './header/RemoveAllFilters';

export default function Selections({
  showParameters,
  showSelectedDateRanges,
}: {
  showParameters: boolean;
  showSelectedDateRanges: boolean;
}) {
  const dispatch = useDispatch();

  const { filters, activePage, parameterOptions } = useAppSelector(
    (state) => state.filters,
  );

  const { selectedDate } = useAppSelector((state) => state.retailCalendar);

  const { primaryRangeObject, comparisonRangeObject } = useAppSelector(
    (state) => state.explorer,
  );

  const handleRemoval = (dimension?: string, id?: string | null) => {
    if (dimension === 'Product.product_id') {
      dispatch(LOAD_SELECTED_PRODUCT());
    }
    dispatch(
      SELECT_DESELECT_FILTER({ dimension, id, activePage, active: true }),
    );
  };

  const handleRangeRemoval = (dimension: string) => {
    if (dimension === 'primary') {
      dispatch(UPDATE_PRIMARY_RANGE(undefined));
    }
    if (dimension === 'comparison') {
      dispatch(UPDATE_COMPARISON_RANGE(undefined));
    }
  };

  const handleSelectedDateRemoval = () => {
    dispatch(SET_SELECTED_DATE(undefined));
  };

  const convertDateFormat = (stringdate: string) =>
    stringdate && stringdate.replace(/-/g, '/');

  return (
    <>
      {selectedDate && (
        <Panel
          label={capitalizeFirstLetter('Reference date')}
          key="reference date"
        >
          <FilterChipOld
            handleRemoval={handleSelectedDateRemoval}
            key="reference date"
            title={selectedDate}
          />
        </Panel>
      )}
      {filters[activePage] &&
        filters[activePage].map((filter) => {
          const { dimension: dimensionId, options, title: name } = filter;
          const activeFilterOptions = options
            ? _filter(options, ['active', true])
            : [];

          const numberOfActiveOptions = activeFilterOptions?.length;
          const selectionLimit = 4;
          const optionChipsToRender = activeFilterOptions?.slice(
            0,
            selectionLimit,
          );
          const optionChipsForLabel =
            activeFilterOptions?.slice(selectionLimit);

          return (
            numberOfActiveOptions > 0 && (
              <Panel
                label={capitalizeFirstLetter(
                  name && name.replace('_desc', '').replace('.', ' '),
                )}
                key={dimensionId}
              >
                {optionChipsToRender.map(({ id, title }) => (
                  <FilterChipOld
                    key={id}
                    id={id}
                    title={title}
                    dimensionId={dimensionId}
                    handleRemoval={handleRemoval}
                  />
                ))}
                {numberOfActiveOptions > selectionLimit && (
                  <LightTooltip
                    title={
                      <FilterTooltip
                        filters={optionChipsForLabel}
                        dimensionId={dimensionId}
                        handleRemoval={handleRemoval}
                      />
                    }
                  >
                    <div>
                      <Label
                        variant="ghost"
                        color="primary"
                        sx={{
                          mr: 0.5,
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        {`+${numberOfActiveOptions - selectionLimit}`}
                      </Label>
                    </div>
                  </LightTooltip>
                )}
              </Panel>
            )
          );
        })}
      {primaryRangeObject && showSelectedDateRanges && (
        <Panel
          label={capitalizeFirstLetter('Primary range')}
          key="primary range"
        >
          <FilterChipOld
            handleRemoval={() => handleRangeRemoval('primary')}
            key="primary range"
            title={`${convertDateFormat(
              primaryRangeObject?.startDate,
            )} to ${convertDateFormat(primaryRangeObject?.endDate)}`}
          />
        </Panel>
      )}
      {comparisonRangeObject && showSelectedDateRanges && (
        <Panel
          label={capitalizeFirstLetter('Comparison range')}
          key="comparison range"
        >
          <FilterChipOld
            handleRemoval={() => handleRangeRemoval('comparison')}
            key="comparison range"
            title={`${convertDateFormat(
              comparisonRangeObject?.startDate,
            )} to ${convertDateFormat(comparisonRangeObject?.endDate)}`}
          />
        </Panel>
      )}
      {parameterOptions &&
        showParameters &&
        parameterOptions.map((filter) => {
          const { dimension, options } = filter;
          const activeFilterOptions = _filter(options, ['active', true]);
          return (
            activeFilterOptions.length > 0 &&
            activeFilterOptions.map((data) => (
              <Panel key={data?.title} label={capitalizeFirstLetter(dimension)}>
                <Chip
                  label={capitalizeFirstLetter(data?.title)}
                  size="small"
                  sx={{ m: 0.5 }}
                />
              </Panel>
            ))
          );
        })}
      {/**
       * we render the remove all filters button if there are active filter options OR
       * we are on the explorer page and either a primary or comparison range is selected OR
       * if a reference date is selected
       */}
      {filters[activePage] &&
        filters[activePage].some(
          (filterOption) =>
            filterOption?.options?.filter((option) => option?.active)?.length >
              0 ||
            (showSelectedDateRanges &&
              (primaryRangeObject || comparisonRangeObject)) ||
            selectedDate,
        ) && <RemoveAllFilters />}
    </>
  );
}

Selections.propTypes = {
  showParameters: PropTypes.bool,
  showSelectedDateRanges: PropTypes.bool,
};
