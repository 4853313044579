import { Label } from '@autone/ui';
import PropTypes from 'prop-types';
import React from 'react';

import {
  comparePercent,
  comparePercentExplorer,
} from '../../config/retail/calcs';

const ComparePercent = ({
  dataRow,
  currentKey,
  compareKey,
  totalSum,
  calculationType,
}) => {
  // convert strings to number
  const currentVal = (dataRow && 1 * dataRow[currentKey]) || 0;
  const compareVal = (dataRow && 1 * dataRow[compareKey]) || 0;

  if (!currentVal || !compareVal) {
    return comparePercent(dataRow, currentKey, totalSum);
  }

  return (
    <Label
      variant="filled"
      color={currentVal > compareVal ? 'success' : 'error'}
    >
      {calculationType === 'explorer'
        ? comparePercentExplorer(dataRow, currentKey, compareKey)
        : comparePercent(dataRow, currentKey, totalSum)}
    </Label>
  );
};

export default ComparePercent;

ComparePercent.propTypes = {
  dataRow: PropTypes.object,
  currentKey: PropTypes.string,
  compareKey: PropTypes.string,
  totalSum: PropTypes.bool,
  calculationType: PropTypes.string,
};
