import { capitalizeFirstLetter } from '@autone/utils';
import { Chip } from '@mui/material';

const FilterChip = ({
  id,
  title,
  dimensionId,
  handleRemoval,
}: {
  id?: string | null;
  title?: string | null;
  dimensionId?: string;
  handleRemoval: (dimensionId?: string, id?: string | null) => void;
}) => (
  <Chip
    label={capitalizeFirstLetter(title)}
    onDelete={() => {
      handleRemoval(dimensionId, id);
    }}
    size="small"
    sx={{ m: 0.5 }}
  />
);

export default FilterChip;
