import { NAVBAR } from '@autone/utils';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { type ReactNode } from 'react';

// @ts-expect-error
import Scrollbar from '../Scrollbar';
import { type FullScreenContainer } from '../table/types';

const getFilterLabel = (
  label: string,
  showNumberOfSelections: boolean,
  numberOfActiveFilterOptions: number,
) => {
  if (!showNumberOfSelections || !numberOfActiveFilterOptions) {
    return label;
  }

  return `${label} (${numberOfActiveFilterOptions})`;
};

const FilterSidebarWrapper = ({
  buttonColor = 'primary',
  labels = {
    header: 'Filters',
    clearAll: 'Clear all',
  },
  isOpen,
  onOpen,
  onClose,
  onClearAllClick,
  children,
  numberOfActiveFilterOptions,
  isFullScreen,
  fullScreenContainer,
  showNumberOfSelections = true,
  isButtonDisabled = false,
}: {
  buttonColor?: 'primary' | 'secondary' | 'inherit';
  labels?: {
    header: string;
    clearAll: string;
  };
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClearAllClick?: () => void;
  children: ReactNode;
  numberOfActiveFilterOptions: number;
  showNumberOfSelections?: boolean;
  isFullScreen?: boolean;
  fullScreenContainer?: FullScreenContainer;
  isButtonDisabled?: boolean;
}) => {
  const areFiltersSelected = numberOfActiveFilterOptions > 0;

  return (
    <>
      <Button
        color={areFiltersSelected ? 'primary' : buttonColor}
        variant="outlined"
        disableRipple
        startIcon={<FilterListIcon />}
        onClick={onOpen}
        disabled={isButtonDisabled}
      >
        {getFilterLabel(
          labels.header,
          showNumberOfSelections,
          numberOfActiveFilterOptions,
        )}
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: NAVBAR.BASE_WIDTH },
        }}
        container={
          isFullScreen && fullScreenContainer
            ? fullScreenContainer.current
            : undefined
        }
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pb: 1, pt: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 2 }}>
            {labels.header}
          </Typography>
          <IconButton onClick={onClose} sx={{ mr: 1 }}>
            <CloseIcon width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar className="scrollbar" sx={{}}>
          {children}
        </Scrollbar>

        <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={!areFiltersSelected}
            fullWidth
            variant="outlined"
            onClick={onClearAllClick}
            sx={{ textTransform: 'none' }}
          >
            {labels.clearAll}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default FilterSidebarWrapper;
