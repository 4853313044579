export { sizeSorter } from './sizeSorter';

export const sortByList = <T extends Record<any, any>, K extends keyof T>(
  data: T[] | undefined,
  sortedList: string[],
  key: K,
) => {
  if (!data) return undefined;

  return [...data].sort((a, b) => {
    const aIndex = a[key] as string;
    const bIndex = b[key] as string;

    return sortedList.indexOf(aIndex) - sortedList.indexOf(bIndex);
  });
};
