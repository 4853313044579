// @ts-nocheck
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  useTheme,
} from '@mui/material';
import {
  alpha,
  type CustomTheme,
  experimentalStyled as styled,
} from '@mui/material/styles';

import useCollapseDrawer from '../../hooks/useCollapseDrawer';

import { NAVBAR } from './consts';
import SidebarLogo from './sidebar/SidebarLogo';

// ----------------------------------------------------------------------

type DashboardNavbarTypes = {
  onOpenSidebar?: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  imageURLPrefix?: string;
  variant?: 'default' | 'alternative' | 'white';
};

export default function DashboardNavbar({
  onOpenSidebar,
  children,
  fullWidth,
  imageURLPrefix = 'https://customer-images.autone.io',
  variant = 'default',
}: DashboardNavbarTypes) {
  const { isCollapse } = useCollapseDrawer();
  const theme = useTheme<CustomTheme>();

  const RootStyle = styled(AppBar)(({
    variant,
    theme,
  }: {
    variant: string;
    theme: CustomTheme;
  }) => {
    const getBackgroundColor = () => {
      if (variant === 'alternative' || variant === 'white') {
        return 'white';
      }

      return alpha(theme.palette.background.default, 0.72);
    };

    return {
      boxShadow: variant === 'alternative' ? theme.customShadows.z1 : 'none',
      backdropFilter: 'blur(6px)',
      WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
      backgroundColor: getBackgroundColor(),
      [theme.breakpoints.up('lg')]: {
        width: fullWidth
          ? `100%`
          : isCollapse
            ? `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 1}px)`
            : `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
      },
    };
  });

  return (
    <RootStyle variant={variant}>
      <Toolbar
        sx={{
          minHeight: NAVBAR.APPBAR_MOBILE,
          [theme.breakpoints.up('lg')]: {
            minHeight: NAVBAR.APPBAR_DESKTOP,
            padding:
              variant === 'alternative'
                ? theme.spacing(0, 0)
                : theme.spacing(0, 5),
          },
        }}
      >
        {!fullWidth && (
          <Hidden lgUp>
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: 'text.primary', zIndex: 100 }}
              size="large"
            >
              <Icon icon={menu2Fill} />
            </IconButton>
          </Hidden>
        )}
        {fullWidth && (
          <Box sx={{ minWidth: '50px', pr: 2 }}>
            <SidebarLogo
              sx={{
                maxHeight: '40px',
              }}
              logo={`${imageURLPrefix}/autone-logos/autone-icon-new.png`}
            />
          </Box>
        )}

        {children}
      </Toolbar>
    </RootStyle>
  );
}
