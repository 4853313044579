import { AutoneTranslation, i18nAutone } from '@autone/translations';
import { AppShell, ErrorFallback, useAppSettings } from '@autone/ui';
import { authRedirectCheckRtkQuery } from '@autone/ui/src/utils/auth';
import { INSIGHTS_READONLY } from '@autone/utils';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// cubejs

import { APP_NAME } from './config/consts';
import { cubeAPIUrlDict } from './cube';
import store from './redux/store';
import AppRoutes from './routes/app.routes';

function App() {
  const routes = [AppRoutes];

  const cubejsApi = cubejs(
    async () => {
      const { idToken } = await authRedirectCheckRtkQuery();
      return idToken;
    },
    {
      apiUrl:
        process.env.REACT_APP_CUBE_API_URL ||
        cubeAPIUrlDict[process.env.REACT_APP_BUILD_STAGE],
    },
  );

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AutoneTranslation namespace={APP_NAME} i18n={i18nAutone}>
        <CubeProvider cubejsApi={cubejsApi}>
          <AppShell
            reduxStore={store}
            routes={routes}
            permissionGroups={[INSIGHTS_READONLY]}
          />
        </CubeProvider>
      </AutoneTranslation>
    </ErrorBoundary>
  );
}

export default App;
