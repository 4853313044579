import { Box } from '@mui/material';
import { type ReactNode } from 'react';

const Navbar = ({
  contentBeginning,
  contentMiddle,
  contentEnd,
}: {
  contentBeginning: ReactNode;
  contentMiddle: ReactNode;
  contentEnd: ReactNode;
}) => (
  <>
    {contentBeginning}
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        overflowX: 'scroll',
        gap: '10px',
        px: 2,
      }}
    >
      {contentMiddle}
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {contentEnd}
    </Box>
  </>
);

export default Navbar;
