import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type ActivePage,
  type Dimension,
  type SelectedVariance,
  type ValueVolume,
} from '../types';
import { type FilterOutput, type Option } from '../types/filters';
import { isSelectedVariance, isValueVolume } from '../types/guards';

export type ParameterOption = {
  title: string;
  dimension: Dimension;
  renderInSidebar: boolean;
  options: {
    id: SelectedVariance | ValueVolume;
    dimension: Dimension;
    title: string;
    active?: boolean;
  }[];
};

type FilterState = {
  subheader: string;
  filters: {
    retail: FilterOutput[];
  };
  activePage: ActivePage;
  selectedMetric: ValueVolume;
  selectedVariance: SelectedVariance;
  parameterOptions: ParameterOption[];
};

const initialState: FilterState = {
  subheader: 'filters',
  filters: {
    retail: [],
  },
  activePage: 'retail',
  selectedMetric: 'value',
  selectedVariance: 'last-year',
  parameterOptions: [
    {
      title: 'Metric',
      dimension: 'metric',
      renderInSidebar: true,
      options: [
        {
          id: 'value',
          dimension: 'metric',
          title: 'Value',
          active: true,
        },
        {
          id: 'volume',
          dimension: 'metric',
          title: 'Volume',
        },
      ],
    },
    {
      title: 'Variance',
      dimension: 'variance',
      renderInSidebar: true,
      options: [
        {
          id: 'this-year',
          dimension: 'variance',
          title: 'This year',
        },
        {
          id: 'last-year',
          dimension: 'variance',
          title: 'Last year',
          active: true,
        },
        {
          id: 'last-last-year',
          dimension: 'variance',
          title: 'Last last year',
        },
        {
          id: 'last-last-last-year',
          dimension: 'variance',
          title: '3 years ago',
        },
        {
          id: 'last-last-last-last-year',
          dimension: 'variance',
          title: '4 years ago',
        },
      ],
    },
  ],
};

function isValidActivePage(
  page: string,
  state: FilterState,
): page is keyof FilterState['filters'] {
  return page in state.filters;
}

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    LOAD_FILTER_OPTIONS: (
      state,
      action: PayloadAction<{
        data: FilterOutput[];
        dimension: ActivePage;
      }>,
    ) => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.dimension]: action.payload.data,
      },
      activePage: action.payload.dimension,
    }),
    ADD_SINGLE_SELECTIONS: (
      state,
      action: PayloadAction<{
        activePage: ActivePage;
        dimension: string;
        renderInSidebar: boolean;
        operator: string;
        option: Option;
      }>,
    ) => {
      const {
        activePage,
        dimension,
        renderInSidebar,
        operator,
        option: optionPayload,
      } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === dimension
              ? {
                  ...item,
                  renderInSidebar,
                  operator,
                  options: item.options.map((option) =>
                    option.id === optionPayload.id
                      ? { ...option, active: true }
                      : option,
                  ),
                }
              : item,
          ),
        },
        activePage: activePage,
      };
    },

    ADD_MULTIPLE_SELECTIONS: (
      state,
      action: PayloadAction<{
        activePage: ActivePage;
        dimension: string;
        renderInSidebar: boolean;
        operator: string;
        options: Option[];
      }>,
    ) => {
      const { activePage, dimension, renderInSidebar, operator, options } =
        action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === dimension
              ? {
                  ...item,
                  renderInSidebar,
                  operator,
                  options: item.options.map((option) => {
                    const foundOption = options.find(
                      (item) => item.id === option.id,
                    );

                    if (foundOption) {
                      return { ...option, active: true };
                    }

                    return option;
                  }),
                }
              : item,
          ),
        },
        activePage: action.payload.activePage,
      };
    },
    ADD_DATE_SELECTIONS: (
      state,
      action: PayloadAction<{
        activePage: string;
        dimension: string;
        renderInSidebar: boolean;
        operator: string;
        options: Option[];
        title: string;
      }>,
    ) => {
      const {
        activePage,
        dimension,
        renderInSidebar,
        operator,
        options,
        title,
      } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].concat({
            dimension,
            operator,
            renderInSidebar,
            options,
            title,
          } as FilterOutput),
        },
      };
    },
    REMOVE_FILTER_OPTONS: (
      state,
      action: PayloadAction<{
        dimension: string;
      }>,
    ) => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.dimension]: undefined,
      },
    }),
    PATCH_FILTER_OPTIONS: (
      state,
      action: PayloadAction<{
        activePage: string;
        currentDimension: string;
        targetDimension: string;
      }>,
    ) => {
      const { activePage, currentDimension, targetDimension } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === currentDimension
              ? {
                  ...item,
                  dimension: targetDimension,
                }
              : item,
          ),
        },
      };
    },
    EDIT_FILTER_SELECTION: (
      state,
      action: PayloadAction<{
        activePage: string;
        dimension: string;
        options: Option[];
      }>,
    ) => {
      const { activePage, dimension, options } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === dimension
              ? {
                  ...item,
                  options: options,
                }
              : item,
          ),
        },
      };
    },
    SELECT_DESELECT_FILTER: (
      state,
      action: PayloadAction<{
        activePage: string;
        id?: string | null;
        dimension?: string;
        active: boolean;
      }>,
    ) => {
      const { activePage, id, active, dimension } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === dimension
              ? {
                  ...item,
                  options: item.options.map((option) =>
                    option.id === id ? { ...option, active: !active } : option,
                  ),
                }
              : item,
          ),
        },
      };
    },
    SELECT_DESELECT_ALL_BY_DIMENSION: (
      state,
      action: PayloadAction<{
        activePage: string;
        dimension: string;
        active: boolean;
      }>,
    ) => {
      const { activePage, dimension, active } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === dimension
              ? {
                  ...item,
                  options: item.options.map((option) => ({
                    ...option,
                    active,
                  })),
                }
              : item,
          ),
        },
      };
    },
    REMOVE_FILTER_CHIP: (
      state,
      action: PayloadAction<{
        activePage: string;
        dimension: string;
      }>,
    ) => {
      const { activePage, dimension } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) =>
            item.dimension === dimension
              ? {
                  ...item,
                  options: item.options.map((option) => ({
                    ...option,
                    active: false,
                  })),
                }
              : item,
          ),
        },
      };
    },
    REMOVE_ALL_CURRENT_FILTERS: (
      state,
      action: PayloadAction<{
        activePage: string;
      }>,
    ) => {
      const { activePage } = action.payload;

      if (!isValidActivePage(activePage, state)) {
        return state;
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          [activePage]: state.filters[activePage].map((item) => ({
            ...item,
            options: item.options.map((option) => ({
              ...option,
              active: false,
            })),
          })),
        },
      };
    },
    SELECT_DESELECT_PARAMETER: (
      state,
      action: PayloadAction<{
        dimension: 'metric' | 'variance';
        id: SelectedVariance | ValueVolume;
      }>,
    ) => ({
      ...state,
      selectedMetric:
        action.payload.dimension === 'metric' &&
        isValueVolume(action.payload.id)
          ? action.payload.id
          : state.selectedMetric,
      selectedVariance:
        action.payload.dimension === 'variance' &&
        isSelectedVariance(action.payload.id)
          ? action.payload.id
          : state.selectedVariance,
      parameterOptions: [
        ...state.parameterOptions.map((item) =>
          item.dimension === action.payload.dimension
            ? {
                ...item,
                options: item.options.map((option) =>
                  option.id === action.payload.id
                    ? { ...option, active: true }
                    : { ...option, active: false },
                ),
              }
            : item,
        ),
      ],
    }),
  },
});

export const {
  LOAD_FILTER_OPTIONS,
  SELECT_DESELECT_FILTER,
  SELECT_DESELECT_ALL_BY_DIMENSION,
  SELECT_DESELECT_PARAMETER,
  REMOVE_FILTER_CHIP,
  ADD_SINGLE_SELECTIONS,
  ADD_MULTIPLE_SELECTIONS,
  ADD_DATE_SELECTIONS,
  REMOVE_ALL_CURRENT_FILTERS,
} = filterSlice.actions;

export default filterSlice.reducer;
