import { LEGACY_FILTER_APPS } from '@autone/utils';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Divider, Fade, List, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import { type Filter, type FilterWithActive } from '../../types';
import { getSortedFilters } from '../../utils';
import { type FullScreenContainer } from '../table/types';

import { type OptionsType } from './FilterItems';
import FilterList from './FilterList';
import FilterSidebarWrapper from './FilterSidebarWrapper';
import RadioItems from './RadioItems';

type FilterSidebarProps<T> = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  filterOptions: Record<string, FilterWithActive<T>[] | undefined>;
  activePage: string;
  handleFilterClickCallback: (
    dimension: string,
    id: string,
    active: boolean,
    type: string,
    description: string,
  ) => void;
  handleSelectDeselectAllByDimension: ({
    dimension,
    active,
    dimensionOptions,
  }: {
    dimension: string;
    active: boolean;
    dimensionOptions: OptionsType;
  }) => void;
  initialHiddenOptions?: string[];
  numOptionsDefaultOpen?: number;
  appName: string;
  radioFilters?: any[];
  handleRadioSelection?: (id: string, value?: string) => void;
  renderParameters?: React.ReactNode;
  handleRemoveAll: () => void;
  filterSortKey?: keyof Filter;
  buttonColor?: 'inherit' | 'primary' | 'secondary';
  showNumberOfSelections?: boolean;
  labels?: {
    header: string;
    lessOptions: string;
    moreOptions: string;
    clearAll: string;
    selectAllOptions: (optionCount: number) => string;
    clearAllOptions: string;
    loadMore: string;
    searchPlaceholder: string;
  };
  isFullScreen?: boolean;
  fullScreenContainer?: FullScreenContainer;
  isButtonDisabled?: boolean;
};

export default function FilterSidebar<T>({
  isOpen,
  onOpen,
  onClose,
  handleFilterClickCallback,
  handleSelectDeselectAllByDimension,
  filterOptions,
  activePage,
  initialHiddenOptions = [],
  numOptionsDefaultOpen = 0,
  appName,
  radioFilters,
  handleRadioSelection,
  handleRemoveAll,
  renderParameters,
  filterSortKey = 'dimension_id',
  buttonColor = 'primary',
  showNumberOfSelections = false,
  labels = {
    header: 'Filters',
    lessOptions: 'Less options',
    moreOptions: 'More options',
    clearAll: 'Clear all',
    selectAllOptions: () => 'Select all options',
    clearAllOptions: 'Clear all options',
    loadMore: 'Load More',
    searchPlaceholder: 'search...',
  },
  isFullScreen,
  fullScreenContainer,
  isButtonDisabled = false,
}: FilterSidebarProps<T>) {
  // state for showing / hiding filter options.
  const [showHiddenOptions, setShowHiddenOptions] = useState(false);

  const sortedFilters = useMemo(
    () => getSortedFilters({ activePage, filterOptions, filterSortKey }),
    [filterOptions, activePage, filterSortKey],
  );

  // returns true if there are filters which exist in initialHiddenOptions. (i.e. which need to be hidden)
  const hiddenOptionExistInFilters = useMemo(() => {
    // workaround for Insights having a different structure. For these apps we need to use dimension, not dimensionId
    const dim = LEGACY_FILTER_APPS.includes(appName)
      ? 'dimension'
      : 'dimension_id';

    return sortedFilters[activePage]?.some((data) =>
      initialHiddenOptions.includes(data?.[dim] || ''),
    );
  }, [sortedFilters]);

  const numberOfActiveFilterOptions = useMemo(
    () =>
      sortedFilters[activePage]?.reduce(
        (count, current) =>
          count + current.options.filter((item) => item.active).length,
        0,
      ),
    [sortedFilters],
  );

  return (
    <FilterSidebarWrapper
      buttonColor={buttonColor}
      labels={{
        header: labels.header,
        clearAll: labels.clearAll,
      }}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClearAllClick={handleRemoveAll}
      numberOfActiveFilterOptions={numberOfActiveFilterOptions || 0}
      showNumberOfSelections={showNumberOfSelections}
      isFullScreen={isFullScreen}
      fullScreenContainer={fullScreenContainer}
      isButtonDisabled={isButtonDisabled}
    >
      <Stack>
        <Stack>
          {/* render parameters used by insights, because the api is different we cannot reuse RadioItems from the package */}
          {renderParameters}
          {radioFilters && (
            <List sx={{ mt: 1 }}>
              {radioFilters.map(
                ({ id, title, priceTypeOptions, selectedPriceType }, i) => (
                  <RadioItems
                    key={i}
                    id={id}
                    title={title}
                    radioOptions={priceTypeOptions}
                    selectedRadioOption={selectedPriceType}
                    handleRadioSelection={handleRadioSelection}
                  />
                ),
              )}
              <Divider sx={{ mt: 1 }} />
            </List>
          )}

          <FilterList
            // @ts-ignore
            filterOptions={sortedFilters[activePage]?.filter(
              ({ dimension }) =>
                !initialHiddenOptions.includes(dimension || ''),
            )}
            handleFilterClickCallback={handleFilterClickCallback}
            handleSelectDeselectAllByDimension={
              handleSelectDeselectAllByDimension
            }
            numOptionsDefaultOpen={numOptionsDefaultOpen}
            appName={appName}
            labels={{
              selectAll: labels.selectAllOptions,
              clearAll: labels.clearAllOptions,
              loadMore: labels.loadMore,
              searchPlaceholder: labels.searchPlaceholder,
            }}
          />
        </Stack>

        {/* We render the Less / More options buttons if there are hidden filters */}
        {hiddenOptionExistInFilters ? (
          showHiddenOptions ? (
            <Button
              onClick={() => setShowHiddenOptions(false)}
              startIcon={<RemoveIcon />}
              sx={{ textTransform: 'none' }}
            >
              {labels.lessOptions}
            </Button>
          ) : (
            <Button
              onClick={() => setShowHiddenOptions(true)}
              startIcon={<AddIcon />}
              sx={{ textTransform: 'none' }}
            >
              {labels.moreOptions}
            </Button>
          )
        ) : (
          <></>
        )}
        {/* we render the additional filter options based on whether showHiddenOptions is true / false */}
        <Fade in={showHiddenOptions}>
          <div>
            <FilterList
              // @ts-ignore
              filterOptions={sortedFilters[activePage]?.filter(
                ({ dimension }) =>
                  initialHiddenOptions.includes(dimension || ''),
              )}
              handleFilterClickCallback={handleFilterClickCallback}
              handleSelectDeselectAllByDimension={
                handleSelectDeselectAllByDimension
              }
              appName={appName}
              labels={{
                selectAll: labels.selectAllOptions,
                clearAll: labels.clearAllOptions,
                loadMore: labels.loadMore,
              }}
            />
          </div>
        </Fade>
      </Stack>
    </FilterSidebarWrapper>
  );
}
